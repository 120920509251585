import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { useLang } from '../context/siteContext'
import { Logo, ChevronDown, Magnifyer, Hamburger, Cross} from '~components/Svg'
import Section from '~components/Section'
import { mobile, tablet, ColorButton } from '~styles/global'
import resolveLink from '~utils/resolveLink'
import { useSiteState } from '~context/siteContext'
import Multi from '~components/Multi'
import useSiteSettings from '~utils/useSiteSettings'
import SanityLink from '~components/SanityLink'
import useBreakpoint from '~utils/useBreakpoint'
import SearchBar from '~components/SearchBar'
import useStaticWindowHeight from '~utils/useStaticWindowHeight'

const LanguageSelector = ({ className }) => {
	const [lang, setLang] = useLang()
	const [open, setOpen] = useState()
	const { isMobile } = useBreakpoint()

	const setAndClose = language => {
		setLang(language)
		setOpen(false)
	}

	return (
		<LanguageDropdown>
			<Selected className='nav' onClick={() => setOpen(!open)}>
				{isMobile ? 
					<>
						{lang === 'english'  && 'EN'}
						{lang === 'tetum' && 'TE'}
					</>
					:
					lang
				}<Chevron open={open}/>
			</Selected>
			<Dropdown open={open}>
				{lang === 'tetum' && <DropDownSelector onClick={() => setAndClose('english')} className='nav'>{isMobile ? 'EN' : 'English'}</	DropDownSelector>}
				{lang === 'english' &&  <DropDownSelector onClick={() => setAndClose('tetum')} className='nav'>{isMobile ? 'TE' : 'Tetum'}</DropDownSelector>}
			</Dropdown>
		</LanguageDropdown>
	)
}

const LanguageDropdown = styled.div`
	position: relative;
	margin-right: 34px;
	line-height: 0;
	${mobile}{
		margin-right: 13px;
		top: -1px;
		position: relative;
	}
`
const Selected = styled(ColorButton)`
	
`
const Dropdown = styled.div`
	position: absolute;
	opacity: ${props => props.open ? '1' : '0'};
	top: calc(100% + 5px);
`
const DropDownSelector = styled(ColorButton)`
	margin-bottom: 5px;
`
const Chevron = styled(ChevronDown)`
	width: 13px;
	margin-left: 2px;
	transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
	transition: transform 0.2s; 
	top: -2px;
	position: relative;
`

const Header = ({ className, white }) => {

	const windowHeight = useStaticWindowHeight()
	const [siteState, setSiteState] = useSiteState()
	const { navigationLinks, searchButtonText, searchPlaceholderText, menuButtonText, closeButtonText, supportText } = useSiteSettings()
	const { isMobile, loaded } = useBreakpoint()

	
	const toggleMenu = () => {
		setSiteState(prevState => ({
			...prevState,
			menuOpen: !siteState.menuOpen,
			searchOpen: false,
		}))
	}

	const toggleSearch = () => {
		setSiteState(prevState => ({
			...prevState,
			menuOpen: false,
			searchOpen: !siteState.searchOpen,
		}))
	}

	return(
		<>
			{loaded && 
			<Wrap className={className} white={white}>
				<Section>
					<Inner>
						<NavLeft>
							<MenuButton 
								className='nav' 
								onClick={() => toggleMenu()}>
								{siteState.menuOpen ? <Multi>{closeButtonText}</Multi> : <Multi>{menuButtonText}</Multi>}
							</MenuButton>
						</NavLeft>
						<LogoContainer to={'/'}>
							<StyledLogo />
						</LogoContainer>
						<NavRight>
							<LanguageSelector />
							<Search className='nav'
								onClick={() => toggleSearch()}
							> 
								<SearchIcon ><Magnifyer /></SearchIcon>
								{!isMobile && <Multi>{searchButtonText}</Multi>}
							</Search>
							{isMobile &&
								<MobileMenuToggle 
									onClick={() => toggleMenu()}>
									{siteState.menuOpen ? 
										<Close/>	
										:
										<Open />
									}	
								</MobileMenuToggle>
							}
						</NavRight>
					</Inner>
				</Section>
			</Wrap>
			}
			<Menu open={siteState.menuOpen}>
				<MenuInner height={windowHeight}>
					{navigationLinks.map(navLink => (
						<MenuLinkContainer key={navLink._key} >
							<MenuLink className='h1' link={navLink.link}>
								<Multi>{navLink.text}</Multi>
							</MenuLink>
						</MenuLinkContainer>
					))
					}
					{isMobile && 
						<Tagline className='sm'><Multi>{supportText}</Multi></Tagline>
					}
				</MenuInner>

			</Menu>
			<SearchDropdown open={siteState.searchOpen}>
				<SearchInner>
					<SearchBar placeholder={searchPlaceholderText}/>
				</SearchInner>
			</SearchDropdown>
		</>
	)
}

const Wrap = styled.header`
  position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: 100;
	color: ${props => props.white ? 'var(--white)' : 'var(--black)'};
	transition: color 0.2s;
`
const Inner = styled.div`
	grid-column: span 12;
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 100px;
	align-items: center;
	${mobile}{
		grid-template-columns: max-content 1fr;
		height: 86px;
	}
`
const MenuButton = styled(ColorButton)`

`
const LogoContainer = styled(Link)`
	justify-self: center;
	grid-column: 1/3;
	grid-row: 1;	
	${mobile}{
		grid-column: 1;
	}
`
const StyledLogo = styled(Logo)`
	width: 138px;
	${tablet}{
		width: 110px;
	}
	${mobile}{
		width: 100%;
		max-width: 99px;
	}
`
const NavLeft = styled.div`
	grid-row: 1;
	grid-column: 1/2;
	${mobile}{
		display: none;
	}
`
const NavRight = styled.div`
	grid-row: 1;
	display: flex;
	grid-column: 2/3;
	justify-self: end;
	${mobile}{
		align-items: center;
	}
`
const Search = styled(ColorButton)`
	display: flex;
`
const SearchIcon = styled.div`
	margin-right: 9px;
	position: relative;
	width: 17px;
	height: 15px;
	display: inline-block;
	>svg{
		position: absolute;
		width: 17px;
		left: 0;
		top: -1px;
	}
	${tablet}{
		width: 16px;
		height: 12px;
	}
	${mobile}{
		>svg{
			top: -3px;
		}
	}
`
const Menu = styled.div`
	position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: 99;
	transform: ${props => props.open ? 'translateY(0%)' : 'translateY(-100%)'};
	transition: transform 0.3s;
	background-color: var(--beige);
	${mobile}{
		height: 100vh;
	}
`
const MenuInner = styled(Section)`
	margin-top: 166px;
	margin-bottom: 36px;
	${mobile}{
		margin-top: 0px;
		margin-bottom: 0px;
		height: ${ props => props.height}px;
		display: flex;
    align-items: center;
		position: relative;
	}
`
const MenuLinkContainer = styled.div`
		grid-column: span 12;
		margin-bottom: 30px;
		${mobile}{
			margin-top: 20px;
			margin-bottom: 20px;
		}
`
const MenuLink = styled(SanityLink)`
	display: inline-block;
	transition: color 0.25s;
	:hover {
		color: var(--purple);
	}
`
const MobileMenuToggle = styled.div`
	margin-left: 6px;
`
const SearchDropdown = styled(Menu)`
	min-height: 511px;
`
const SearchInner = styled(MenuInner)`
	
`
const Tagline = styled.div`
	position: absolute;
	bottom: 0;
	padding: 25px 0px;
	box-sizing: border-box;
`
const Open = styled(Hamburger)`
	width: 20px;
	position: relative;
	top: -4px;
`
const Close = styled(Cross)`
	width: 12px;
	position: relative;
	top: -3px;
`

Header.propTypes = {
	className: PropTypes.string,
	white: PropTypes.bool,
}

export default Header
